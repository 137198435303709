import React from "react";
import Layout from "../components/layout";

const metaInfo = {
  title: "Page Not Found | Task Analytics",
  description: "Oops!",
};

const NotFoundPage = () => {
  return (
    <Layout metaInfo={metaInfo}>
      <div class="container features">
        <div class="row">
          <div class="col-10 col-xs-12 col-sm-10 col-md-8 col-lg-8 mx-auto">
            <h1 class="text-center">Page Not Found</h1>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
